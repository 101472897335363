import "javascripts/twitter.typeahead";
import Bloodhound from "javascripts/bloodhound";

export default class SearchTypeahead {

  start () {
    const $keywords = $(".js-typeahead-input");
    // This is needed as a workaround for an issue with the twitter-typeahead
    // library in which the library doesn't check if the input is already
    // initialized resulting in duplicated elements when using the Turbolinks
    // cache.
    if ($keywords.length > 2) {
      $keywords.typeahead("destroy");
      $("[name='keywords']").unwrap();
      $(".js-typeahead-input:not([name='keywords'])").remove();
    }

    $keywords.bind('typeahead:select', (e) => {
      $(e.target).parents(".js-typeahead-form").submit()
    });

    $keywords.on("keyup", function (e) {
      if (e.keyCode == 13) {
        $(e.target).parents(".js-typeahead-form").submit()
      }
    })

    $keywords.typeahead({
      minLength: 0,
      highlight: true
    }, {
      name: 'products',
      source: this.productsWithDefaults
    });
  }

  productsWithDefaults (q, sync) {
    var products = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch: '/typeahead.json'
    });

    if (q === '') {
      let suggestions = $('#keywords').data('suggestions');
      sync(products.get(suggestions));
    } else {
      products.search(q, sync);
    }
  }
}

new SearchTypeahead().start();
